@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');


body {
  margin: 0;
  font-family: 'Comfortaa';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
